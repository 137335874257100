import React, { createContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cursor, setCursor] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [partialNotification, setPartialNotification] = useState({});

  useEffect(() => {
    if (partialNotification && Object.keys(partialNotification).length > 0) {
      const updatedNotifications = notifications.map((notification) =>
        notification.id === partialNotification.id
          ? partialNotification
          : notification
      );
      setNotifications(updatedNotifications);
    }
  }, [partialNotification]);

  function markNotificationAsRead(notificationId) {
    axios
      .post(
        `${process.env.REACT_APP_API_V2_URL}/business-notifications/${notificationId}/mark-read/`
      )
      .then((response) => {
        setUnreadCount(response.data.unread_count);
        setPartialNotification(response.data.notification);
      })
      .catch((error) => {
        console.error("Error marking notification as read:", error);
      });
  }

  function markAllNotificationsAsRead() {
    axios
      .post(
        `${process.env.REACT_APP_API_V2_URL}/business-notifications/mark-all-read/`
      )
      .then((response) => {
        setUnreadCount(response.data.unread_count);
        setNotifications(response.data.notifications);
      })
      .catch((error) => {
        console.error("Error marking all notifications as read:", error);
      });
  }

  const fetchNotifications = async (cursor) => {
    setLoading(true);
    try {
      let url =
        cursor || `${process.env.REACT_APP_API_V2_URL}/business-notifications`;
      const response = await axios.get(url);

      setUnreadCount(response.data.unread_count);

      setNotifications((prev) => {
        const combinedNotifications = [...prev, ...response.data.results];

        const uniqueNotifications = Array.from(
          new Map(
            combinedNotifications.map((notification) => [
              notification.id,
              notification,
            ])
          ).values()
        );

        return uniqueNotifications;
      });

      setCursor(response.data.next);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
    setLoading(false);
  };

  return (
    <NotificationsContext.Provider
      value={useMemo(
        () => ({
          notifications,
          loading,
          unreadCount,
          fetchNotifications,
          cursor,
          markNotificationAsRead,
          markAllNotificationsAsRead,
        }),
        [
          notifications,
          loading,
          unreadCount,
          fetchNotifications,
          cursor,
          markNotificationAsRead,
          markAllNotificationsAsRead,
        ]
      )}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
